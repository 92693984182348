































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Testimonial } from './PageTestimonials.vue'

@Component<ATestimonialsSliderItem>({})
export default class ATestimonialsSliderItem extends Vue {
  @Prop({ default: null }) item!: Testimonial

  content = ''

  mounted() {
    this.$nextTick(() => {
      return this.content = this.item?.content || ''
    })
  }
}
