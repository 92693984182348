import { render, staticRenderFns } from "./PageTestimonials.vue?vue&type=template&id=a71e7b9e&lang=pug&"
import script from "./PageTestimonials.vue?vue&type=script&lang=ts&"
export * from "./PageTestimonials.vue?vue&type=script&lang=ts&"
import style0 from "./PageTestimonials.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageTestimonialsSlider: require('/var/www/imoli.app/components/Page/PageTestimonials/PageTestimonialsSlider.vue').default})
