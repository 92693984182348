






import { Component, Vue } from "nuxt-property-decorator";

export interface Testimonial {
  author: {
    image: string;
    name: string;
    position?: string;
    links: {
      label: string;
      url: string;
    }[];
  };
  company?: {
    name: string;
    logo: string;
  };
  content: string;
}

@Component<OTestimonials>({})
export default class OTestimonials extends Vue {
  items_en: Testimonial[] = [
    // {
    //   author: {
    //     image: require('@/assets/img/customers/testimonials/a3xd.png'),
    //     name: 'Dominik Wydmuch',
    //     position: 'Członek Zarządu w Bracia Wydmuch Sp. z o.o.',
    //     links: [
    //       { label: 'Google', url: 'https://goo.gl/maps/uRmR1hP2DsVYss4S6' }
    //     ]
    //   },
    //   company: {
    //     name: 'A3XD',
    //     logo: require('@/assets/img/customers/logos/A3XD.png')
    //   },
    //   content: 'Profesjonalne i cierpliwe podejście do klienta, strona internetowa przygotowana zgodnie z naszymi wymaganiami, szybki czas realizacji oraz bardzo dobry kontakt mailowy i telefoniczny. Polecam firmę Imoli! :)'
    // },

    {
      author: {
        image: require("@/assets/img/customers/testimonials/gradek.webp"),
        name: "Kamil Gradek",
        position: "A player of the professional CCC Team",
        links: [
          {
            label: "Facebook",
            url:
              "https://pl-pl.facebook.com/kamil.gradek/posts/3817863088242183"
          },
          { label: "Google", url: "https://goo.gl/maps/cJXD949bfjzfsepN7" }
        ]
      },
      company: {
        name: "Kamil Gradek",
        logo: require("@/assets/img/customers/logos/kamil-gradek.png")
      },
      content:
        "Thank you for making my website. I can finally show my achievements on the Internet. I like speed and precision. I like a professional and quick approach to life. Imoli company took great care of my website. As I like it. Thanks gentlemen!"
    },

    {
      author: {
        image: require("@/assets/img/customers/testimonials/kubicki.webp"),
        name: "Krzysztof Kubicki",
        position: "CEO at Ipsum Technics",
        links: [{ label: "Google", url: "#!" }]
      },
      company: {
        name: "Ipsum Technics",
        logo: require("@/assets/img/customers/logos/ipsum_technics.png")
      },
      content:
        "I am pleased to recommend Imoli as an interactive agency. Imoli specialists have created a website for my company with a convenient panel for updating it. After completing the website, I could count on remote or on-site technical support. Imoli is a very good contractor. I recommend."
    }
  ];

  items_pl: Testimonial[] = [
    // {
    //   author: {
    //     image: require('@/assets/img/customers/testimonials/a3xd.png'),
    //     name: 'Dominik Wydmuch',
    //     position: 'Członek Zarządu w Bracia Wydmuch Sp. z o.o.',
    //     links: [
    //       { label: 'Google', url: 'https://goo.gl/maps/uRmR1hP2DsVYss4S6' }
    //     ]
    //   },
    //   company: {
    //     name: 'A3XD',
    //     logo: require('@/assets/img/customers/logos/A3XD.png')
    //   },
    //   content: 'Profesjonalne i cierpliwe podejście do klienta, strona internetowa przygotowana zgodnie z naszymi wymaganiami, szybki czas realizacji oraz bardzo dobry kontakt mailowy i telefoniczny. Polecam firmę Imoli! :)'
    // },

    {
      author: {
        image: require("@/assets/img/customers/testimonials/gradek.webp"),
        name: "Kamil Gradek",
        position: "Zawodnik profesjonalnej drużyny CCC Team",
        links: [
          {
            label: "Facebook",
            url:
              "https://pl-pl.facebook.com/kamil.gradek/posts/3817863088242183"
          },
          { label: "Google", url: "https://goo.gl/maps/cJXD949bfjzfsepN7" }
        ]
      },
      company: {
        name: "Kamil Gradek",
        logo: require("@/assets/img/customers/logos/kamil-gradek.png")
      },
      content:
        "Dziękuję za wykonanie mojej strony www. Nareszcie mogę pokazać moje osiągnięcia w Internecie. Lubię prędkość i precyzję. Lubię profesjonalne i szybkie podejście do życia. Firma Imoli wspaniale zajęła się realizacją mojej strony. Tak jak lubię. Dzięki Panowie!"
    },

    {
      author: {
        image: require("@/assets/img/customers/testimonials/kubicki.webp"),
        name: "Krzysztof Kubicki",
        position: "CEO w Ipsum Technics",
        links: [{ label: "Google", url: "#!" }]
      },
      company: {
        name: "Ipsum Technics",
        logo: require("@/assets/img/customers/logos/ipsum_technics.png")
      },
      content:
        "Z przyjemnością polecam firmę Imoli jako agencję interaktywną. Specjaliści z Imoli stworzyli dla mojej firmy stronę internetową wraz z wygodnym zapleczem do jej aktualizacji. Po wykonaniu strony mogłem liczyć na pomoc techniczną zdalną lub u nas na miejscu w firmie. Imoli to bardzo dobry kontrahent. Polecam."
    }
  ];

  items_de: Testimonial[] = [
    // {
    //   author: {
    //     image: require('@/assets/img/customers/testimonials/a3xd.png'),
    //     name: 'Dominik Wydmuch',
    //     position: 'Członek Zarządu w Bracia Wydmuch Sp. z o.o.',
    //     links: [
    //       { label: 'Google', url: 'https://goo.gl/maps/uRmR1hP2DsVYss4S6' }
    //     ]
    //   },
    //   company: {
    //     name: 'A3XD',
    //     logo: require('@/assets/img/customers/logos/A3XD.png')
    //   },
    //   content: 'Profesjonalne i cierpliwe podejście do klienta, strona internetowa przygotowana zgodnie z naszymi wymaganiami, szybki czas realizacji oraz bardzo dobry kontakt mailowy i telefoniczny. Polecam firmę Imoli! :)'
    // },

    {
      author: {
        image: require("@/assets/img/customers/testimonials/gradek.webp"),
        name: "Kamil Gradek",
        position: "CCC Team Profisportler",
        links: [
          {
            label: "Facebook",
            url:
              "https://pl-pl.facebook.com/kamil.gradek/posts/3817863088242183"
          },
          { label: "Google", url: "https://goo.gl/maps/cJXD949bfjzfsepN7" }
        ]
      },
      company: {
        name: "Kamil Gradek",
        logo: require("@/assets/img/customers/logos/kamil-gradek.png")
      },
      content:
        "Vielen Dank für die Erstellung meiner Website. Endlich kann ich meine Erfolge im Internet zeigen. Ich mag Geschwindigkeit und Präzision. Ich schätze einen professionellen und schnellen Ansatz im Leben. Die Firma Imoli hat sich hervorragend um die Umsetzung meiner Seite gekümmert. Genau wie ich es mag. Danke, meine Herren!"
    },

    {
      author: {
        image: require("@/assets/img/customers/testimonials/kubicki.webp"),
        name: "Krzysztof Kubicki",
        position: "CEO bei Ipsum Technics",
        links: [{ label: "Google", url: "#!" }]
      },
      company: {
        name: "Ipsum Technics",
        logo: require("@/assets/img/customers/logos/ipsum_technics.png")
      },
      content:
        "Mit Freude empfehle ich die Firma Imoli als interaktive Agentur. Die Spezialisten von Imoli haben für mein Unternehmen eine Website erstellt, inklusive eines komfortablen Backends für deren Aktualisierung. Nach Fertigstellung der Seite konnte ich auf technische Unterstützung, entweder ferngesteuert oder direkt bei uns im Unternehmen, zählen. Imoli ist ein sehr guter Geschäftspartner. Ich empfehle sie."
    }
  ];

  get items() {
    if (this.$i18n.locale == "pl") return this.items_pl;
    if (this.$i18n.locale == "de") return this.items_de;
    else return this.items_en;
  }
}
