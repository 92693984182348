











import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Testimonial } from './PageTestimonials.vue'

@Component<ATestimonialsSliderNavigation>({})
export default class ATestimonialsSliderNavigation extends Vue {
  @Prop({ default: 0 }) value!: number
  @Prop({ default: () => [] }) items!: Testimonial[]

  setSlide(index: number, event: MouseEvent) {
    this.$emit('input', index)
  }
}
