import { render, staticRenderFns } from "./PageTestimonialsSliderItem.vue?vue&type=template&id=0fc709d2&lang=pug&"
import script from "./PageTestimonialsSliderItem.vue?vue&type=script&lang=ts&"
export * from "./PageTestimonialsSliderItem.vue?vue&type=script&lang=ts&"
import style0 from "./PageTestimonialsSliderItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmoothReflow: require('/var/www/imoli.app/components/SmoothReflow.vue').default})
