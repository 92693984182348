

















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Testimonial } from './PageTestimonials.vue'

@Component<MTestimonialsSlider>({})
export default class MTestimonialsSlider extends Vue {
  @Prop({ default: () => [] }) items!: Testimonial[]

  current = 0

  interval: any

  next() {
    if (this.current < this.items.length - 1) {
      this.current++
    } else {
      this.current = 0
    }
  }

  prev() {
    if (this.current > 0) {
      this.current--
    } else {
      this.current = this.items.length - 1
    }
  }

  mounted() {
    this.interval = setInterval(this.next, 20000)
  }

  stopAuto() {
    clearInterval(this.interval)
  }
}
